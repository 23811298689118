import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const Template = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <>
      <SEO
        lang='en'
        meta={[
          {
            property: "og:image",
            content: `https://digstories.io${post.frontmatter.postPicture.publicURL}`,
          },
          {
            property: "twitter:image",
            content: `https://digstories.io${post.frontmatter.postPicture.publicURL}`,
          },
          {
            property: "og:site_name",
            content: "Dig Stories",
          },
          {
            property: "article:author",
            content: post.frontmatter.url,
          },
          {
            property: "article:published_time",
            content: post.frontmatter.date,
          },
          {
            property: "og:url",
            content: `https://digstories.io/blog${post.frontmatter.path}/`,
          },
          {
            name: "author",
            content: post.frontmatter.author,
          },
        ]}
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt}
        type='article'
      />
      <Layout>
        <article className='inline-block lg:flex lg:flex-col lg:items-center min-h-screen font-primary'>
          <div className='lg:max-w-3xl'>
            <h1 className='mx-5 text-3xl font-extrabold leading-tight mt-5'>
              {post.frontmatter.title}
            </h1>
            <h2 className='mx-5 text-md text-gray-400 leading-tight'>
              {post.frontmatter.excerpt}
            </h2>
            <div className='mx-5 flex flex-row my-5'>
              <Img
                fluid={post.frontmatter.profilePicture.childImageSharp.fluid}
                className='rounded rounded-full w-12'
              />
              <div className='flex flex-col mx-5'>
                <h4 className='w-full'>{post.frontmatter.author}</h4>
                <div className='flex flex-row w-full text-sm text-gray-400'>
                  <h5 className='mr-1'>{post.frontmatter.displayDate}</h5> -
                  <h6 className='ml-1'>{post.timeToRead} min read</h6>
                </div>
              </div>
            </div>
            <Img
              fluid={post.frontmatter.postPicture.childImageSharp.fluid}
              className='my-5'
            />
            <div
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: post.html }}
              className='mx-5 article-content'
            />
          </div>
        </article>
      </Layout>
    </>
  );
};

export const postQuery = graphql`
  query($slug: String) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      id
      timeToRead
      frontmatter {
        title
        displayDate: date(formatString: "MMMM DD")
        date
        path
        tags
        excerpt
        author
        url
        postPicture {
          publicURL
          childImageSharp {
            fluid(maxWidth: 820) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        profilePicture {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default Template;
